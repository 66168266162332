@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'flowbite';

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.loader {
  border-top-color: #3498db;
  animation: spinner 0.6s linear infinite;
}

button {
  border: none;
}